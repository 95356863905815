@import "../v";

//============== Property Listing ====================//

.property-cards {
	padding: 15px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(auto-fill, auto);
	grid-row-gap: 30px;
	grid-column-gap: 30px;
	overflow: hidden;
	.card {
		width: 100%;
		background-color: white;
		@include vendorPrefix("border-radius", "5px");
		@include vendorPrefix("box-shadow", "0 0 10px rgba(0,0,0,0.04)");
		overflow: hidden;
		position: relative;
		.has-slider {
			padding: 0px;
			.sliders {
				position: relative;
				display: block;
				._single {
					height: 248px;
					img {
						width: 100%;
						height: auto;
						display: none;
					}
				}
			}
		}
		.desc {
			padding: 15px 20px 20px 20px;
			border: solid 1px;
			border-color: #f3f4f6;
			border-top: none;
			@include vendorPrefix("border-bottom-left-radius", "4px");
			@include vendorPrefix("border-bottom-right-radius", "4px");
		}
		&:hover {
			.desc {
				border-color: $colorSecondary;
			}
			._title {
				color: $colorSecondary;
			}
		}
	}
}
//================== Property Details ====================//

.property-detail {
	.wrap {
		max-width: 650px;
		border: solid 1px #f3f4f6;
		hr {
			outline: none;
			border: none;
			height: 1px;
			width: 100%;
			background-color: #f3f4f6;
			margin: 10px 0;
		}
		.sliders {
			position: relative;
			._single {
				height: 420px;
				img {
					width: 100%;
					height: auto;
					display: none;
				}
			}
		}
	}
}

//================== Create Loan Application ====================//

.create-loan-application {
	.row {
		padding: 40px 20px;
		border: solid 1px #f0f0f0;
		@include vendorPrefix("box-shadow", "0 0 20px rgba(0,0,0,0.08)");
		.wrap {
			max-width: 860px;
		}
		table {
			min-width: 420px;
			display: table;
			table-layout: fixed;
			border-collapse: separate;
			border-spacing: 10px 0;
			tbody {
				tr {
					td {
						padding: 10px 0;
					}
				}
			}
		}
	}
}

.loan-application-stepper {
	padding: 20px 0;
	.step {
		width: 120px;
		position: relative;
		&:after {
			position: absolute;
			width: 100%;
			content: "";
			height: 4px;
			background: #cfcfcf;
			bottom: 10px;
			left: 50%;
			margin-left: 12px;
		}
		&:last-child {
			&::after {
				display: none;
			}
		}
	}
	.icon {
		height: 24px;
		width: 24px;
		display: block;
		margin: 0 auto;
		svg {
			height: 24px;
			width: 24px;
		}
	}
	.incomplete {
		svg {
			path {
				fill: #cfcfcf;
			}
		}
	}
	.complete {
		svg {
			path {
				fill: $colorPrimary;
			}
		}
		&:after {
			background: $colorPrimary;
		}
	}
}

.loan-application-stepper-fixed-position {
	background-color: white;
	position: fixed;
	z-index: 99;
	@include calc("width", "100% - 300px");
	top: 0;
	border-bottom: solid 2px #fafafb;
}

.stepper-should-impact-fixed-position {
	margin-top: 86px;
}

//================== Application Detail ====================== //

.credit-score-application-detail {
	background-color: white;
	height: 102px;
	width: 102px;
	border: solid 10px #f0f0f0;
	position: absolute;
	top: -52px;
	right: -52px;
	font-size: 20px;
	font-weight: 900;
	color: $colorSecondary;
}

//================= Config ========================== //

.config-cards {
	padding: 15px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(auto-fill, auto);
	grid-row-gap: 30px;
	grid-column-gap: 30px;

	.config-card {
		background-color: white;
		@include vendorPrefix("border-radius", "5px");
		@include vendorPrefix("box-shadow", "0 0 10px rgba(0,0,0,0.04)");
		position: relative;

		.desc {
			padding: 15px 20px 20px 20px;
			border: solid 1px;
			border-color: #f3f4f6;
			border-top: none;
			@include vendorPrefix("border-bottom-left-radius", "4px");
			@include vendorPrefix("border-bottom-right-radius", "4px");
		}

		&:hover {
			.desc {
				border-color: $colorSecondary;
			}
		}
	}
}

