@import "./v";

* {
	margin: 0;
	padding: 0;
	@include vendorPrefix("box-sizing", "border-box");
}
html {
	scroll-behavior: smooth;
}
body {
	height: auto;
	overflow: auto;
	position: relative;
	background: white;
	color: #17181f;
	font-family: "Inter", sans-serif;
}
// .inter-<uniquifier> {
// 	font-family: "Inter", sans-serif;
// 	font-optical-sizing: auto;
// 	font-weight: <weight>;
// 	font-style: normal;
// 	font-variation-settings:
// 	  "slnt" 0;
//   }
h1 {
	font-size: 40px;
}
h2 {
	font-size: 34px;
}
h3 {
	font-size: 30px;
}
h4 {
	font-size: 26px;
}
h5 {
	font-size: 22px;
}
h6 {
	font-size: 18px;
}
p {
	font-size: 16px;
	line-height: 1.5;
}
p.small {
	font-size: 12px;
}
a {
	color: inherit;
	text-decoration: none;
}
.f-w-200 {
	font-weight: 200;
}
.f-w-300 {
	font-weight: 300;
}
.f-w-400 {
	font-weight: 400;
}
.f-w-500 {
	font-weight: 500;
}
.f-w-600 {
	font-weight: 600;
}
.f-w-700 {
	font-weight: 700;
}
.f-w-800 {
	font-weight: 800;
}
.f-w-900 {
	font-weight: 900;
}
.pad-20 {
	padding: 20px;
}
.default-image {
	background: url(/images/icons/default.png) no-repeat center center;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
}
.b-r-5 {
	@include vendorPrefix("border-radius", "5px");
}
.b-r-8 {
	@include vendorPrefix("border-radius", "8px");
}
.b-r-10 {
	@include vendorPrefix("border-radius", "10px");
}
.b-r-20 {
	@include vendorPrefix("border-radius", "20px");
}
.b-r-100 {
	@include vendorPrefix("border-radius", "100%");
}
.b {
	@include vendorPrefix("border", " solid 1px");
}
.flex {
	display: flex;
}
.dis-b {
	display: block;
}
.center-center {
	justify-content: center;
	align-items: center;
}
.center-left {
	justify-content: left;
	align-items: center;
}
.top-left {
	justify-content: flex-start;
	align-items: flex-start;
}
.center-right {
	justify-content: right;
	align-items: center;
}
.bottom-left {
	justify-content: flex-start;
	align-items: flex-end;
}
.bottom-right {
	justify-content: flex-end;
	align-items: flex-end;
}
.flex-1 {
	flex: 1;
}
.col-1-5 {
	width: 20%;
}
.col-1-4 {
	width: 25%;
}
.col-3-10 {
	width: 30%;
}
.col-1-3 {
	width: 33.33%;
}
.col-2-5 {
	width: 40%;
}
.col-1-2 {
	width: 50%;
}
.col-3-5 {
	width: 60%;
}
.col-2-3 {
	width: 66.66%;
}
.col-7-10 {
	width: 70%;
}
.col-3-4 {
	width: 75%;
}
.col-4-5 {
	width: 80%;
}
.col-1-1 {
	width: 100%;
}
.pos-rel {
	position: relative;
}
.of-hid {
	overflow: hidden;
}
.of-vis {
	overflow: visible;
}
.t-t-u {
	text-transform: uppercase;
}
.t-a-c {
	text-align: center;
}
.t-a-r {
	text-align: right;
}
.t-a-l {
	text-align: left;
}
.t-d-ul {
	text-decoration: underline;
}
.l-h-1 {
	line-height: 1;
}
.l-h-1-2 {
	line-height: 1.2;
}
.l-h-1-5 {
	line-height: 1.5;
}
.l-h-2 {
	line-height: 2;
}
.cursor-pointer {
	cursor: pointer;
}
.color-white {
	color: white;
}
.color-primary {
	color: $colorPrimary;
}
.color-secondary {
	color: $colorSecondary;
}
.color-tertiary {
	color: $colorTertiary;
}
.color-light {
	color: $colorLight;
}
.mar-l-a {
	margin-left: auto;
}
.mar-10-0 {
	margin: 10px 0;
}
.mar-20-0 {
	margin: 20px 0;
}
.transition {
	@include vendorPrefix("transition", "all 0.2s");
}
.hover-color-secondary {
	&:hover {
		color: $colorSecondary;
	}
}
.hover-color-primary {
	&:hover {
		color: $colorPrimary;
	}
}
.hover-color-white {
	&:hover {
		color: white;
	}
}
.bg-color-primary {
	background-color: $colorPrimary;
}
.bg-color-secondary {
	background-color: $colorSecondary;
}
.bg-color-tertiary {
	background-color: $colorTertiary;
}
.bg-color-faded {
	background-color: #fafafb;
}
.font-size-14 {
	font-size: 14px;
}
.font-size-12 {
	font-size: 12px;
}
.color-faded {
	color: #8f8f8f;
}

// ====== Form Fields ====== //

input,
select,
textarea,
button {
	border: none;
	outline: none;
	resize: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	-ms-appearance: none;
	appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner,
select::-moz-focus-inner,
textarea::-moz-focus-inner {
	outline: none !important;
}
select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #ffffff;
}
textarea {
	-webkit-rtl-ordering: logical;
	-moz-rtl-ordering: logical;
	-o-rtl-ordering: logical;
	-ms-rtl-ordering: logical;
	-rtl-ordering: logical;
	-webkit-user-select: text;
	-moz-user-select: text;
	-o-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
textarea::-ms-expand {
	display: none;
}
::-webkit-input-placeholder {
	color: #8f8f8f;
}
::-moz-placeholder {
	color: #8f8f8f;
}
::-o-placeholder {
	color: #8f8f8f;
}
:-ms-input-placeholder {
	color: #8f8f8f;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 100px #ffffff inset !important;
	-webkit-text-fill-color: #444 !important;
}
input,
select,
textarea,
.file-input-holder,
.ui-like-input {
	padding: 0 16px;
	line-height: 44px;
	height: 44px;
	font-size: 14px;
	@include vendorPrefix("border-radius", "4px");
	width: 100%;
	display: block;
	@include vendorPrefix("transition", "all 0.2s");
	border: solid 1px;
	color: #373737;
	border-color: #f3f4f6ff;
	background-color: #f3f4f6ff;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	overflow: hidden;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

select {
	background: #f3f4f6ff url("/images/icons/select.svg") right center no-repeat;
	cursor: pointer;
}

input[type="checkbox"],
input[type="radio"] {
	height: 24px;
	width: 24px;
	padding: 0;
	position: relative;
	cursor: pointer;
	@include vendorPrefix("transition", "none");
}
input[type="checkbox"] {
	@include vendorPrefix("border-radius", "4px");
}
input[type="checkbox"],
input[type="checkbox"]:focus,
input[type="radio"],
input[type="radio"]:focus {
	border-color: $colorPrimary;
}
input[type="checkbox"]:checked {
	border-color: $colorPrimary;
	background-color: $colorPrimary;
}
input[type="checkbox"]:checked:before,
input[type="checkbox"]:checked:after,
input[type="radio"]:checked:before,
input[type="radio"]:checked:after {
	position: absolute;
	content: "";
}
input[type="checkbox"]:checked:before,
input[type="checkbox"]:checked:after {
	@include vendorPrefix("transform-origin", "top left");
	@include vendorPrefix(
		"transform",
		"rotate(-45deg) translateY(13px) translateX(-6px)"
	);
}
input[type="checkbox"]:checked:before {
	height: 6px;
	width: 12px;
	left: 0;
	top: -2px;
	background: white;
}
input[type="checkbox"]:checked:after {
	height: 6px;
	width: 12px;
	left: 0px;
	top: -4px;
	background: $colorPrimary;
}
input[type="radio"],
input[type="radio"]:checked:before,
input[type="radio"]:checked:after {
	@include vendorPrefix("border-radius", "100%");
}
input[type="radio"]:checked {
	border-color: $colorPrimary;
}
input[type="radio"]:checked:before {
	height: 18px;
	width: 18px;
	top: 2px;
	left: 2px;
	background: $colorSecondary;
}
input[type="radio"]:checked:after {
	height: 14px;
	width: 14px;
	top: 4px;
	left: 4px;
	background: $colorPrimary;
}
.file-input-holder {
	cursor: pointer;
	input[type="file"] {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
		@include opacity0();
	}
	svg {
		position: absolute;
		height: 16px;
		width: auto;
		display: block;
		top: 16px;
		right: 12px;
		path {
			fill: $colorPrimary;
		}
	}
}
textarea {
	height: 180px;
	padding: 16px;
	line-height: 16px;
	overflow: scroll;
}
input.error,
select.error,
textarea.error {
	border-color: red;
}
p.error-messages {
	font-size: 11px;
	padding: 5px 0;
	line-height: 1.2;
	color: red;
}

p.success-messages {
	font-size: 11px;
	padding: 5px 0;
	line-height: 1.2;
	color: green;
}

button {
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0 22px;
	height: 44px;
	line-height: 44px;
	font-size: 16px;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	cursor: pointer;
	@include vendorPrefix("transition", "all 0.2s");
	@include vendorPrefix("border-radius", "6px");
	background-color: $colorPrimary;
	color: #fff;
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		img {
			height: 16px;
		}
		svg {
			height: 20px;
			width: auto;
			path {
				fill: white;
				@include vendorPrefix("transition", "all 0.2s");
			}
		}
	}
	&:hover {
		color: $colorSecondary;
		span {
			svg {
				path {
					fill: $colorSecondary;
				}
			}
		}
	}
	&:disabled {
		background-color: #aaa;
		color: #cfcfcf;
		cursor: not-allowed;
	}
}
button.light {
	background-color: #f3f4f6ff;
	color: $colorPrimary;
	span {
		svg {
			height: 12px;
			width: auto;
			display: block;
			path {
				fill: $colorPrimary;
			}
		}
	}
	&:hover {
		color: white;
		background-color: $colorSecondary;
		svg {
			path {
				fill: white;
			}
		}
	}
}

// ====== RS Layout ====== //

main {
	aside {
		width: 300px;
		height: 100vh;
		background: #fafafbff;
		align-items: normal;
		display: flex;
		flex-direction: column;
		position: fixed;
		top: 0;
		left: 0;
		.top {
			padding: 30px 20px;
			.logo {
				margin-bottom: 15px;
				svg {
					height: 48px;
					width: auto;
					display: block;
				}
			}
			.user-card {
				background-color: white;
				padding: 20px 15px;
				border: solid 1px #f3f4f6ff;
				@include vendorPrefix("border-radius", "16px");
				display: flex;
				gap: 12px;
				.icon {
					height: 40px;
					width: 40px;
					background: $colorPrimary;
					svg {
						height: 24px;
						width: auto;
						display: block;
						path {
							fill: white;
						}
					}
				}
				p.username {
					height: 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 172px;
				}
				.definition {
					flex: 1;
				}
			}
		}
		.middle {
			-webkit-box-flex: 1;
			flex-grow: 1;
			overflow-y: auto;
			padding: 0 20px;
			nav {
				ul {
					li {
						list-style: none;
						margin-bottom: 10px;
						line-height: 44px;
						position: relative;
						padding: 0 12px;
						&:after {
							position: absolute;
							content: "";
							top: 0;
							left: 0;
							height: 100%;
							width: 0px;
							background: $colorPrimary;
							@include vendorPrefix("transition", "all 0.2s");
							@include vendorPrefix(
								"border-top-right-radius",
								"4px"
							);
							@include vendorPrefix(
								"border-bottom-right-radius",
								"4px"
							);
						}
						a {
							display: flex;
							align-items: center;
							font-size: 16px;
							gap: 6px;
							color: #4b5362;
							font-weight: 400;
							@include vendorPrefix("transition", "all 0.2s");
							svg {
								height: 26px;
								width: auto;
								path {
									fill: #4b5362;
									@include vendorPrefix(
										"transition",
										"all 0.2s"
									);
								}
							}
							&:hover {
								color: $colorPrimary;
								svg {
									path {
										fill: $colorPrimary;
									}
								}
							}
						}
					}
					li.active {
						&:after {
							width: 4px;
						}
						a {
							color: $colorPrimary;
							font-weight: 700;
							svg {
								path {
									fill: $colorPrimary;
									stroke-width: 0.25px;
								}
							}
						}
					}
				}
			}
		}
		.bottom {
			margin: 0px;
			padding: 0px;
			border: 0px;
			vertical-align: baseline;
			padding: 20px 20px 10px 20px;
			.logout {
				border: solid 1px #f3f4f6ff;
				background-color: white;
				@include vendorPrefix("border-radius", "16px");
				display: flex;
				align-items: center;
				padding: 20px;
				cursor: pointer;
				svg {
					height: 28px;
					width: auto;
					display: block;
					margin-left: auto;
					path {
						fill: $colorPrimary;
					}
				}
			}
			.version {
				margin-top: 20px;
				text-align: center;
				p {
					color: $colorPrimary;
					font-size: 9px;
				}
			}
		}
	}
	.container {
		height: auto;
		@include calc("width", "100% - 300px");
		margin-left: auto;
		padding-bottom: 40px;
	}
}

// ====== Header ====== //

header {
	padding: 30px 20px;
	display: flex;
	border-bottom: solid 2px #fafafb;
	.menus {
		margin-left: auto;
		gap: 10px;
		.icon {
			height: 32px;
			width: 32px;
			background-color: #f0f0f0;
			color: $colorPrimary;
			svg {
				height: 20px;
				width: auto;
				display: block;
				cursor: pointer;
				path {
					stroke-width: 1px;
				}
			}
		}
	}
}

.notification {
	position: relative;
	.new {
		&:after {
			position: absolute;
			content: "";
			top: -2px;
			right: 0px;
			height: 8px;
			width: 8px;
			@include vendorPrefix("border-radius", "100%");
			background-color: orangered;
			border: solid 1px $colorPrimary;
		}
	}
	.list {
		position: absolute;
		background-color: #ffffff;
		border: solid 1px #f0f0f0;
		right: 0px;
		top: 40px;
		@include vendorPrefix("border-radius", "6px");
		padding: 0;
		width: 300px;
		z-index: 99;
		@include vendorPrefix("box-shadow", "0 0 20px rgba(0,0,0,0.08)");
		ul.tab {
			border-bottom: solid 1px #f0f0f0;
			display: flex;
			gap: 10px;
			padding: 4px 4px 0 16px;
			li {
				list-style: none;
				position: relative;
				p {
					font-size: 13px;
					color: $colorPrimary;
					line-height: 1;
					padding: 16px 10px;
					display: block;
					cursor: pointer;
				}
			}
			li.active:after {
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				height: 2px;
				width: 100%;
				background-color: #7af1f7;
			}
			svg.close {
				height: 24px;
				width: auto;
				display: block;
				cursor: pointer;
				margin-left: auto;
				margin-top: 8px;
				margin-right: 4px;
				@include vendorPrefix("transition", "all 0.2s");
				&:hover {
					@include vendorPrefix("transform", "scale(1.12)");
				}
				path {
					fill: $colorSecondary;
				}
			}
		}
		.lists-holder {
			height: 300px;
			overflow-y: scroll;
			.no-data {
				height: 300px;
				display: flex;
				align-items: center;
				justify-content: center;
				.i {
					height: 40px;
					width: 40px;
					display: flex;
					margin: 0 auto;
					@include vendorPrefix("border-radius", "100%");
					background-color: rgba(74, 74, 74, 0.12);
					justify-content: center;
					align-items: center;
					margin-bottom: 10px;
					svg {
						height: 20px;
						width: auto;
						display: block;
						path {
							fill: $colorPrimary;
						}
					}
				}
			}
		}
		ul.lists {
			display: block;
			li {
				list-style: none;
				border-bottom: solid 1px #f0f0f0;
				width: 100%;
				display: flex;
				align-items: center;
				padding: 12px 16px;
				cursor: pointer;
				position: relative;
				.ico {
					height: 36px;
					width: 36px;
					border: solid 1px;
					@include vendorPrefix("border-radius", "100%");
					display: flex;
					align-items: center;
					justify-content: center;
					svg {
						height: 16px;
						width: auto;
						display: block;
					}
				}
				.ico-success {
					border-color: #cfcfcf;
					background-color: #f0f0f0;
				}
				.ico-fail {
					border-color: #cfcfcf;
					background-color: #f0f0f0;
				}
				.t {
					padding-left: 12px;
					padding-right: 48px;
					@include calc("width", "100% - 36px");
					p.m {
						font-size: 12px;
						line-height: 1.2;
					}
					p.d {
						font-size: 9px;
						color: #646464;
					}
				}
				.del {
					position: absolute;
					right: 10px;
					top: 50%;
					margin-top: -16px;
					height: 32px;
					width: 32px;
					@include vendorPrefix("border-radius", "100%");
					z-index: 2;
					cursor: pointer;
					@include vendorPrefix("transition", "all 0.2s");
					padding-top: 10px;
					svg {
						height: 12px;
						width: auto;
						margin: 0 auto;
						display: block;
						path {
							fill: $colorPrimary;
						}
					}
					display: none;
					&:hover {
						background: white;
						svg {
							path {
								fill: $colorSecondary;
							}
						}
					}
				}
				&:hover {
					background-color: #fafafa;
					.del {
						display: block;
					}
				}
			}
		}
	}
}

.profile-info {
	position: absolute;
	background-color: #ffffff;
	border: solid 1px #f0f0f0;
	right: 0px;
	top: 40px;
	@include vendorPrefix("border-radius", "6px");
	padding: 0;
	width: 160px;
	z-index: 99;
	@include vendorPrefix("box-shadow", "0 0 20px rgba(0,0,0,0.08)");
	ul {
		li {
			display: block;
			font-size: 13px;
			list-style: none;
			border-bottom: solid 1px #f0f0f0;
			text-align: right;
			a {
				display: block;
				padding: 12px 16px;
			}
			&:last-child {
				border-bottom: none;
			}
		}
		li.active {
			background: rgba(0, 0, 0, 0.02);
			color: $colorSecondary;
		}
	}
}

// ====== Search Bar ====== //

.search-bar {
	.row {
		display: flex;
		align-items: center;
		input {
			background-color: white;
			border-color: #e8e8e8;
			@include vendorPrefix("border-top-right-radius", "0px");
			@include vendorPrefix("border-bottom-right-radius", "0px");
		}
		button {
			@include vendorPrefix("border-top-left-radius", "0px");
			@include vendorPrefix("border-bottom-left-radius", "0px");
			background-color: $colorSecondary;
			&:hover {
				background-color: $colorPrimary;
			}
		}
	}
}

// ====== RS Tabs ====== //

.rs-tabs {
	ul.tab-selector {
		display: flex;
		align-items: center;
		gap: 16px;
		li {
			list-style: none;
			font-size: 16px;
			color: $colorPrimary;
			font-weight: 400;
			position: relative;
			padding: 0 16px;
			line-height: 44px;
			border-bottom: solid 6px;
			border-color: transparent;
			@include vendorPrefix("transition", "all 0.2s");
			cursor: pointer;
			&:hover {
				border-color: $colorSecondary;
			}
		}
		li.active {
			font-weight: 700;
			border-color: $colorPrimary;
		}
	}
	.tab-content {
		margin-top: 20px;
	}
}

// ====== RS Table ====== //

.rs-table-holder {
	padding: 0px 20px 20px 20px;
	overflow-x: scroll;
}

table.rs-table {
	width: 100%;
	display: table;
	border-collapse: collapse;
	border: solid 1px #f9f9fa;
	@include vendorPrefix("border-top-left-radius", "10px");
	@include vendorPrefix("border-bottom-left-radius", "10px");
	thead {
		tr {
			th {
				background-color: $colorPrimary;
				text-align: left;
				color: white;
				font-size: 16px;
				font-weight: 600;
				padding: 24px 20px;
				white-space: nowrap;
				&:first-child {
					@include vendorPrefix("border-top-left-radius", "8px");
				}
				&:last-child {
					@include vendorPrefix("border-top-right-radius", "8px");
				}
			}
		}
	}
	tbody {
		tr {
			td {
				text-align: left;
				font-size: 14px;
				font-weight: 400;
				padding: 15px 15px 15px 20px;
				white-space: nowrap;
				.status {
					@include vendorPrefix("border-radius", "15px");
					padding: 0 15px;
					width: 100%;
					// display: inline-block;
					display: flex;
					justify-content: center;
					font-size: 13px;
					line-height: 30px;
					color: white;
				}
				.active {
					display: flex;
					justify-content: center;
					background-color: $colorSecondary;
				}
				.inactive {
					background-color: $colorPrimary;
				}
				svg {
					height: 20px;
					width: auto;
					display: block;
					cursor: pointer;
					path {
						@include vendorPrefix("transition", "all 0.2s");
					}
					&:hover {
						path {
							fill: $colorSecondary;
						}
					}
				}
			}
			td.bold {
				font-weight: 700;
			}
			&:nth-child(2n) {
				background-color: #f9f9fa;
			}
		}
	}
}

// ====== RS Table Pagination ====== //

ul.rs-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	li {
		list-style: none;
		cursor: pointer;
		display: block;
		width: 32px;
		height: 32px;
		border: solid 1px;
		border-color: #dfe2e7;
		text-align: center;
		font-size: 13px;
		font-size: 400;
		line-height: 30px;
		color: $colorPrimary;
		justify-content: center;
		@include vendorPrefix("transition", "all 0.2s");
		@include vendorPrefix("border-radius", "4px");
		&:hover {
			background-color: #dfe2e7;
		}
	}
	li.active {
		border-color: $colorPrimary;
		background-color: $colorPrimary;
		color: white;
	}
}

// ====== RS Modal ====== //

#rs-modal {
	position: fixed;
	inset: 0;
	.inner {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		width: 100vw;
		background-color: rgba(255, 255, 255, 0.92);
		.box {
			position: relative;
			background-color: white;
			border: solid 1px #dfe2e7;
			@include vendorPrefix("border-radius", "10px");
		}
	}
}

// ====== RS Search Bar ====== //

.top-search-bar {
	display: flex;
	padding: 25px 20px;
	border-bottom: solid 2px #fafafbff;
	align-items: center;
	.ip-holder {
		margin-left: auto;
		width: 368px;
	}
}

// ====== RS Full Loader ====== //

#rs-loader-overlay {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: white;
	z-index: 99999;
	@include opacity(0.68, 68);
	.inner {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		.content {
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			.spinner {
				width: 40px;
				height: 40px;
				display: inline-block;
				border: solid 2px $colorPrimary;
				border-top-color: #fff;
				@include vendorPrefix("animation", "spin 1s infinite linear");
			}
		}
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-o-keyframes spin {
	100% {
		-o-transform: rotate(360deg);
	}
}
@-ms-keyframes spin {
	100% {
		-ms-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

// ====== RS Search Results ====== //

.search-results {
	background-color: white;
	padding: 0;
	border: solid 1px #f3f4f6;
	@include vendorPrefix("box-shadow", "0 0 10px rgba(0,0,0,0.04)");
	@include vendorPrefix("border-radius", "4px");
	width: 100%;
	height: 192px;
	position: absolute;
	z-index: 999;
	top: 46px;
	overflow-y: scroll;
	ul {
		li {
			border-bottom: solid 1px #f3f4f6;
			line-height: 1;
			padding: 12px 20px;
			font-size: 14px;
			&:last-child {
				border-bottom: none;
			}
			&:hover {
				background-color: #f3f4f6;
				color: $colorSecondary;
			}
		}
	}
}

// ====== RS Toast Messages ====== //

#rs-toast-messages {
	position: fixed;
	z-index: 99999999 !important;
	top: 20px;
	right: 20px;
	.single-toast {
		@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.08)");
		@include vendorPrefix("border-radius", "6px");
		width: 280px;
		padding: 15px 20px 15px 12px;
		background-color: $colorPrimary;
		margin-bottom: 12px;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		@include vendorPrefix("animation", "slide-from-right 0.4s");
		@include vendorPrefix("animation-fill-mode", "both");
		display: flex;
		align-items: center;
		gap: 12px;
		&:last-child {
			margin-bottom: 0;
		}
		.icon {
			background-color: #fafafa;
			height: 60px;
			width: 60px;
			@include vendorPrefix("border-radius", "100%");
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: white;
			svg {
				height: 48px;
				width: 48px;
				display: block;
				margin: 0 auto;
			}
		}
		p {
			font-size: 14px;
			line-height: 1.2;
			font-weight: 300;
			flex: 1;
		}
		hr {
			outline: none;
			border: none;
			height: 4px;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background-color: $colorSecondary;
			@include vendorPrefix("animation", "diminish 4s");
			@include vendorPrefix("animation-fill-mode", "both");
		}
	}
}

@-webkit-keyframes slide-from-right {
	0% {
		-webkit-transform: translateX(200px);
	}
	100% {
		-webkit-transform: translateX(0px);
	}
}
@-moz-keyframes slide-from-right {
	0% {
		-moz-transform: translateX(200px);
	}
	100% {
		-moz-transform: translateX(0px);
	}
}
@-o-keyframes slide-from-right {
	0% {
		-o-transform: translateX(200px);
	}
	100% {
		-o-transform: translateX(0px);
	}
}
@-ms-keyframes slide-from-right {
	0% {
		-ms-transform: translateX(200px);
	}
	100% {
		-ms-transform: translateX(0px);
	}
}
@keyframes slide-from-right {
	0% {
		transform: translateX(200px);
	}
	100% {
		transform: translateX(0px);
	}
}

@-webkit-keyframes diminish {
	0% {
		width: 100%;
	}
	100% {
		width: 0;
	}
}
@-moz-keyframes diminish {
	0% {
		width: 100%;
	}
	100% {
		width: 0;
	}
}
@-o-keyframes diminish {
	0% {
		width: 100%;
	}
	100% {
		width: 0;
	}
}
@-ms-keyframes diminish {
	0% {
		width: 100%;
	}
	100% {
		width: 0;
	}
}
@keyframes diminish {
	0% {
		width: 100%;
	}
	100% {
		width: 0;
	}
}

///// Password-Eye///////

.password-field-wrapper {
	position: relative;
	width: 100%;
	.eye-icon {
		opacity: 0.5;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		display: flex;
		align-items: center;
		font-size: 1.2em;
		color: $colorPrimary;
	}
}

//============ Flag ============///

.custom-select-container {
	position: relative;
	display: flex;
	align-items: center;
}

.custom-select {
	width: 100%;
	border-radius: 4px;
	appearance: none;
	background: F3F4F6;
	padding-left: 40px; /* Space for the flag */
	.custom-select.error {
		border-color: red;
	}
}

.select-icon {
	position: absolute;
	left: 10px;
	display: flex;
	align-items: center;
	pointer-events: none;
}

.select-icon img {
	width: 20px;
	height: 14px;
}

//===============

.input-error {
	border: 1px solid red;
	background-color: #fdd;
}

.upload-success {
	background-color: rgb(116, 228, 116);
}

// ====== Carousel ====== //

.react-multi-carousel-list {
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;
}
.react-multi-carousel-track {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	position: relative;
	transform-style: preserve-3d;
	backface-visibility: hidden;
	will-change: transform, transition;
}
.react-multiple-carousel__arrow {
	position: absolute;
	outline: 0;
	@include vendorPrefix("transition", "all 0.2s");
	@include vendorPrefix("border-radius", "100%");
	z-index: 10;
	border: 0;
	width: 32px;
	height: 32px;
	line-height: 32px;
	padding: 0;
	@include opacity1();
	cursor: pointer;
	background-color: $colorPrimary;
}
.react-multiple-carousel__arrow:hover {
	background-color: $colorPrimary;
	@include vendorPrefix("transform", "scale(1.12)");
}
.react-multiple-carousel__arrow::before {
	line-height: 32px;
	height: 32px;
	width: 32px;
	top: 0;
	padding: 0;
	display: block;
	content: "";
}
.react-multiple-carousel__arrow:disabled {
	cursor: default;
	background: rgba(0, 0, 0, 0.5);
}
.react-multiple-carousel__arrow--left {
	@include calc("left", "0% + 15px");
	&:before {
		background: url("../images/sliders/prev.svg") no-repeat 50% 50%;
	}
}
.react-multiple-carousel__arrow--right {
	@include calc("right", "0% + 15px");
	&:before {
		background: url("../images/sliders/next.svg") no-repeat 50% 50%;
	}
}
.react-multi-carousel-dot-list {
	position: absolute;
	bottom: 12px;
	display: flex;
	left: 0;
	right: 0;
	justify-content: center;
	margin: auto;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}
.react-multi-carousel-dot {
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.react-multi-carousel-dot button {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	@include opacity1();
	padding: 0px 5px;
	box-shadow: none;
	@include vendorPrefix("transition", "background 0.5s");
	border-width: 1px;
	border-style: solid;
	padding: 0;
	margin: 0 2px;
	outline: 0;
	cursor: pointer;
	border-color: white;
	background: transparent;
}
.react-multi-carousel-dot button:hover:active {
	border-color: $colorSecondary;
	background: $colorSecondary;
}
.react-multi-carousel-dot--active button {
	border-color: $colorSecondary;
	background: $colorSecondary;
}
.react-multi-carousel-item {
	transform-style: preserve-3d;
	backface-visibility: hidden;
}
.carousel-button-group {
	.left-nav,
	.right-nav {
		position: absolute;
		top: 50%;
		margin-top: -16px;
		@include vendorPrefix("transition", "all 0.2s");
		@include vendorPrefix("border-radius", "100%");
		height: 32px;
		width: 32px;
		@include opacity1();
		cursor: pointer;
		background-color: $colorPrimary;
		&:hover {
			@include vendorPrefix("transform", "scale(1.12)");
		}
	}
	.left-nav {
		left: -60px;
	}
	.right-nav {
		right: -60px;
	}
	.left-nav:before,
	.right-nav:before {
		line-height: 32px;
		height: 32px;
		width: 32px;
		top: 0;
		padding: 0;
		display: block;
		content: "";
	}
	.left-nav::before {
		// background: url("/images/sliders/prev.svg") no-repeat 50% 50%;
	}
	.right-nav::before {
		// background: url("/images/sliders/next.svg") no-repeat 50% 50%;
	}
}
