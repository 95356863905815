@import "../v";

// ====== Dashboards ====== //

.tenant-applications {
  padding: 20px;
  .single {
    margin-bottom: 20px;
    border: solid 1px;
    padding: 25px;
    border-color: #f3f4f6;
    background-color: white;
    @include vendorPrefix("border-radius", "5px");
    @include vendorPrefix("box-shadow", "0 0 10px rgba(0,0,0,0.04)");
    hr {
      outline: none;
      border: none;
      height: 1px;
      width: 100%;
      background-color: #f3f4f6;
      margin: 10px 0;
    }
  }
}

.credit-score-box {
  width: 300px;
  border: solid 2px #f0f0f0;
  background-color: white;
  padding: 20px;
  @include vendorPrefix("box-shadow", "0 0 20px rgba(0,0,0,0.12)");
  svg {
    height: auto;
    width: 100%;
    display: block;
  }
}

.payment-info-box {
  width: 400px;
  border: solid 1px $colorPrimary;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  @include vendorPrefix("box-shadow", "0 0 20px rgba(0,0,0,0.12)");
  svg {
    height: auto;
    width: 100%;
    display: block;
  }
}

.pre-approvals {
  padding: 20px;
  .congratulations {
    height: 480px;
    padding: 40px;
    .icon {
      height: 80px;
      width: 80px;
      margin: 0 auto;
      margin-bottom: 10px;
      svg {
        height: 40px;
        width: auto;
        display: block;
        path {
          fill: white;
        }
      }
    }
  }


  .configs {
    background-color: #fafafb;
    padding: 20px;
    .all-configs {
      margin-top: 20px;
      .one {
        background-color: white;
        border: solid 1px #f0f0f0;
        margin-bottom: 20px;
        padding: 20px;
        gap: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        .cp {
          .img {
            height: 150px;
            width: 200px;
            border: solid 1px #f0f0f0;
          }
        }
        .options {
          .option-one {
            border-bottom: solid 2px #fafafb;
            margin-bottom: 10px;
            padding-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

#tenant-register {
  .col{
    height: 100vh;
    input, select,p{
      margin-bottom: 8px;
    } 
  }
}
.logo {svg {
  height: 60px;
  width: auto;
  display: block;
}}