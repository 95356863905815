@import "../v";

// ====== Login Page ====== //

#auth-page {
	position: relative;
	overflow: hidden;
	&:before,
	&:after {
		position: absolute;
		content: "";
		background-color: #fafafbff;
		width: 25vw;
		height: 25vw;
		@include vendorPrefix("border-radius", "100%");
		z-index: -1;
	}
	&:before {
		right: 50vw;
		margin-right: 12.5vw;
		top: -12.5vw;
	}
	&:after {
		left: 50vw;
		bottom: -12.5vw;
		margin-left: 12.5vw;
	}
	.auth-header {
		padding: 16px 32px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		.logo {
			svg {
				height: 60px;
				width: auto;
				display: block;
			}
		}
		.options {
			margin-left: auto;
			gap: 20px;
			p {
				a {
					@include vendorPrefix("transition", "all 0.2s");
					&:hover {
						color: $colorSecondary;
					}
				}
			}
		}
	}
	.auth-content {
		.inner {
			max-width: 1180px;
			padding: 0 20px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			.col {
				@include calc("height", "100vh - 92px");
				padding-bottom: 92px;
				display: flex;
				align-items: center;
				.text {
					position: relative;
					padding: 20px 0 0 20px;
					&:after {
						position: absolute;
						content: "";
						top: 0px;
						height: 100%;
						width: 8px;
						left: -20px;
						background-color: $colorSecondary;
						@include vendorPrefix("border-top-right-radius", "6px");
						@include vendorPrefix(
							"border-bottom-right-radius",
							"6px"
						);
					}
				}
				.box {
					width: 386px;
					border: solid 1px;
					border-color: #f0f0f0;
					padding: 48px 40px 40px 40px;
					background-color: white;
					@include vendorPrefix("border-radius", "12px");
					@include vendorPrefix(
						"box-shadow",
						"0 0 40px rgba(0,0,0,0.08)"
					);
					position: relative;
					z-index: 2;
					table {
						width: 100%;
						display: table;
						margin-top: 20px;
						tbody {
							tr {
								td {
									padding: 5px 0;
									p.title {
										font-size: 14px;
										margin-bottom: 5px;
									}
									button {
										width: 100%;
										margin-top: 20px;
									}
									textarea {
										height: 132px;
									}
								}
							}
						}
					}
				}
				.terms-and-conditions {
					border: solid 1px;
					border-color: #f0f0f0;
					padding: 40px;
					background-color: white;
					@include vendorPrefix("border-radius", "12px");
					@include vendorPrefix(
						"box-shadow",
						"0 0 40px rgba(0,0,0,0.08)"
					);
					position: relative;
					width: 100%;
					.scrollable {
						@include calc("height", "100vh - 400px");
						overflow-y: scroll;
						margin: 10px 0 20px 0;
						.content {
							ul {
								li {
									margin-bottom: 10px;
									p {
										font-size: 13px;
									}
								}
							}
						}
					}
				}
				.pending-details {
					width: 420px;
					.verify {
						button {
							width: 102px;
							background-color: transparent;
							border: solid 1px;
							border-color: $colorPrimary;
							padding: 0px;
							margin: 0 0 0 6px;
							line-height: 42px;
							font-size: 14px;
							span {
								svg {
									path {
										fill: $colorPrimary;
									}
								}
							}
							&:hover {
								color: $colorPrimary;
							}
							&:disabled {
								border-color: #cfcfcf;
								color: #cfcfcf;
								span {
									svg {
										path {
											fill: #cfcfcf;
										}
									}
								}
							}
						}
						button[data-name="verified"] {
							background-color: $colorSecondary;
							border-color: $colorSecondary;
							color: white;
							span {
								svg {
									path {
										fill: white;
									}
								}
							}
						}
					}
				}
			}
		}
		svg.svg-top,
		svg.svg-bottom {
			position: absolute;
			z-index: -1;
			height: 50vh;
			width: auto;
			display: block;
			@include opacity(0.12, 12);
		}
		svg.svg-top {
			top: 0;
			right: 0px;
		}
		svg.svg-bottom {
			bottom: 0;
			left: 0;
		}
	}
}

#tenant-auth-page {
	position: relative;
	overflow: hidden;
	.row {
		.col {
			height: 100vh;
		}
		.left {
			background-color: white;
			position: relative;
			width: 600px;
			svg.svg-top,
			svg.svg-bottom {
				position: absolute;
				z-index: 0;
				height: 25vh;
				width: auto;
				display: block;
				@include opacity(0.12, 12);
			}
			svg.svg-top {
				top: 0;
				right: 0px;
			}
			svg.svg-bottom {
				bottom: 0;
				left: 0;
			}
			.box {
				width: 100%;
				padding: 0 80px;
				.logo {
					svg {
						height: 48px;
						width: auto;
						display: block;
					}
				}
				.wc {
					margin: 80px 0 20px 0;
				}
				.form {
					table {
						width: 100%;
						border-collapse: collapse;
						tbody {
							tr {
								td {
									button {
										width: 100%;
										margin-top: 20px;
									}
								}
							}
						}
					}
				}
			}
		}
		.right {
			border: solid 10px white;
			padding: 10px;
			position: relative;
			@include calc("width", "100% - 600px");
			&:after {
				position: absolute;
				inset: 0;
				content: "";
				background-color: $colorPrimary;
				@include opacity(0.86, 86);
				z-index: 2;
			}
			.box {
				position: relative;
				z-index: 3;
				padding: 0 80px;
				.img {
					max-width: 680px;
					position: relative;
					padding: 40px 80px;
					img.main {
						width: 100%;
						height: auto;
						display: block;
						overflow: hidden;
						@include vendorPrefix("border-radius", "8px");
					}
					.top-left,
					.bottom-right {
						position: absolute;
						width: 200px;
						height: auto;
						@include vendorPrefix("border-radius", "4px");
						img {
							width: 100%;
							height: auto;
							display: block;
						}
					}
					.top-left {
						top: 0;
						left: 0;
					}
					.bottom-right {
						bottom: 0;
						right: 0;
					}
				}
				.texts {
					margin-top: 40px;
					text-align: center;
				}
			}
		}
	}
}
