@import "./v";

@media only screen and (max-width: 1540px) {
	.dashboard {
		.quick-links {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	.property-cards {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media only screen and (max-width: 1248px) {
	.dashboard {
		.grid-view {
			grid-template-columns: repeat(1, 1fr);
		}
	}
	.property-cards {
		grid-template-columns: repeat(2, 1fr);
	}
	#tenant-auth-page {
		.row {
			.left {
				width: 480px;
				.box {
					padding: 0 48px;
				}
			}
			.right {
				@include calc("width", "100% - 480px");
				.box {
					.img {
						.top-left,
						.bottom-right {
							width: 120px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	#auth-page {
		.auth-content {
			.inner {
				display: block;
				max-width: 100%;
				margin-bottom: 80px;
				.col {
					height: auto;
					width: 100%;
					padding: 0;
					.text {
						max-width: 480px;
						margin: 0 auto;
						padding: 20px 0 20px 32px;
						&:after {
							left: 0;
						}
					}
					.box {
						width: 480px;
						margin: 0 auto;
					}
				}
				.left {
					padding: 40px 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 1020px) {
	#tenant-auth-page {
		.row {
			display: block;
			.col {
				height: auto;
			}
			.left,
			.right {
				width: 100%;
			}
			.left {
				padding: 40px 0 40px 0;
				.box {
					max-width: 420px;
					margin: 0 auto;
					display: block;
					padding: 0 16px;
					.wc {
						margin: 40px 0 20px 0;
					}
				}
			}
			.right {
				padding: 80px 0 40px 0;
				.box {
					padding: 0 16px;
					.img {
						max-width: 420px;
					}
				}
			}
		}
	}
}
