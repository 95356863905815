@import "./v";

body,
body[data-theme="light"] {
	--background: white;
	--main-color: $colorPrimary;
}

body[data-theme="dark"] {
	--background: $colorPrimary;
	--main-color: white;
}
