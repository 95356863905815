@import "../v";

// ====== Profile Page ====== //

#profile-page {
	padding: 20px;
	.cover-image {
		height: 248px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding: 20px 40px;
	}
	.user-info {
		padding: 0 40px 20px 40px;
		display: flex;
		align-items: flex-end;
		border-bottom: solid 1px #f3f4f6;
		gap: 20px;
		margin-top: -40px;
		.user-picture {
			height: 132px;
			width: 132px;
			border: solid 4px #fafafa;
			.add-picture {
				position: absolute;
				bottom: 0;
				right: 0;
				height: 40px;
				width: 40px;
				background-color: #fafafb;
				svg {
					height: 20px;
					width: auto;
					display: block;
				}
			}
		}
	}
	.about-content {
		background-color: #fafafb;
		padding: 20px;
		display: flex;
		.left {
			width: 268px;
			hr {
				outline: none;
				border: none;
				height: 1px;
				width: 100%;
				margin: 10px 0 10px 0;
				background-color: #f3f4f6;
			}
			ul {
				li {
					list-style: none;
					padding: 15px 15px;
					cursor: pointer;
					&:hover {
						background-color: #f0f0f0;
					}
					margin-bottom: 10px;
				}
				li.active {
					background-color: #e0eeeb;
					color: $colorPrimary;
					font-weight: 700;
				}
			}
		}
		.right {
			@include calc("width", "100% - 268px");
			padding-left: 40px;
		}
	}
}
