@import "../v";

// ====== Dashboards ====== //

.dashboard {
	padding: 20px;
	.quick-links {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-row-gap: 20px;
		grid-column-gap: 20px;
		margin-bottom: 20px;
		.one {
			border: solid 1px;
			padding: 20px 15px 20px 15px;
			border-color: #f3f4f6;
			background-color: white;
			@include vendorPrefix("border-radius", "5px");
			@include vendorPrefix("box-shadow", "0 0 10px rgba(0,0,0,0.04)");
		}
	}
	.grid-view {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-row-gap: 20px;
		grid-column-gap: 20px;
		.one {
			border: solid 1px;
			padding: 25px;
			border-color: #f3f4f6;
			background-color: white;
			@include vendorPrefix("border-radius", "5px");
			@include vendorPrefix("box-shadow", "0 0 10px rgba(0,0,0,0.04)");
		}
	}
}

//==================
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1; 
  }
  
  .modal-content {
	background-color: #fff; 
	padding: 20px;
	border-radius: 8px;
	@include vendorPrefix("box-shadow", "0 0 10px rgba(0,0,0,0.04)");
	max-width: 90%;
	max-height: 80%;
	overflow-y: auto;
  }

