@import "../v";

// ====== Create New Agency ====== //

.create-agency {
	padding: 20px;
	.table-wrapper {
		max-width: 600px;
		border: solid 2px #fafafb;
		padding: 20px 10px 10px 10px;
		@include vendorPrefix("border-radius", "8px");
		table {
			width: 100%;
			display: table;
			table-layout: fixed;
			border-collapse: separate;
			border-spacing: 10px 0;
			tbody {
				tr {
					td {
						padding: 10px 0;
						p.title {
							font-size: 14px;
							margin-bottom: 5px;
						}
					}
					td.btns {
						padding-top: 60px;
						button {
							margin: 10px;
						}
					}
				}
			}
		}
	}
}
